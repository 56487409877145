import React from 'react'
import ProductHeader from '../templates/ProductPage/ProductHeader'
import SEO from '../components/seo'

const PolitiqueExpedition = () => {
  return (
    <>
      <SEO title="Politique de d'expédition" description="Page Politique de d'expédition" />
      <ProductHeader title={'Politique de d\'expédition'} />

      <div className='page-legale'>

        <p><i>applicable à compter du <span className='mention' data-mention='{{runtime:currentDate}}'>22/09/2021</span></i>
        </p>
        <h3><strong>ARTICLE 1. OBJET</strong></h3>
        <p>La présente politique d’expédition a pour objet de définir les termes et conditions applicables à
          l’expédition d’un Produit au Client par l’Editeur.
        </p>
        <h3><strong>ARTICLE 2. PARTIES</strong></h3>
        <p>La présente politique d’expédition est applicable entre <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                                   >JH &amp; Co
        </span>, <span
                                                                     className='mention' data-mention='{{company:companyType}}'
                                                                            >SARL
                                                                            </span>, capital social : <span
                   className='mention' data-mention='{{company:capital}}'
                                           >1000
                                           </span> €, enregistrée au&nbsp;RCS de <span
                                                                                                        className='mention' data-mention='{{company:registrationState}}'
                                                                                                                                            >Creteil
                                                                                                                                            </span> en <span
                                                                                   className='mention'
                                                                                   data-mention='{{company:registrationCountry}}'
                                                                                            >France
                                                                                            </span> le <span
                                                                                                                                                         className='mention' data-mention='{{company:registrationDate}}'
                                                                                                                                                                  >28/11/2016
                                                                                                                                                                  </span>, sous le numéro <span
                                                                                                         className='mention' data-mention='{{company:companyRegistrationID}}'
                                                                                                                               >82391901400014
                                                                                                                               </span>, siège social
          : <span
              className='mention'
              data-mention='{{company:fullAddress}}'
            >1 bis rue blanqui, 94200 Ivry sur seine, FR
          </span>, téléphone
          : <span className='mention' data-mention='{{company:phone}}'>+33620142775</span>, email : <span
              className='mention' data-mention='{{company:email}}'
                                                                                                    >jhandco.dev@gmail.com
          </span>, TVA non applicable,
          article 293B du CGI, ci-après «&nbsp;l’Editeur&nbsp;» et toute personne, physique ou morale, de droit privé ou
          de droit public, inscrite sur le Site pour acheter un Produit, ci-après «&nbsp;le Client&nbsp;».
        </p>
        <p>&nbsp;</p>
        <h3><strong>ARTICLE 3. DEFINITIONS</strong></h3>
        <p>«&nbsp;<strong>Client&nbsp;</strong>» : toute personne, physique ou morale, de droit privé ou de droit
          public, inscrite sur le Site.
        </p>
        <p>«&nbsp;<strong>Contenus du Site&nbsp;»</strong> : éléments de toute nature publiés sur le Site, protégés ou
          non par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas,
          structures, bases de données ou logiciels.&nbsp;
        </p>
        <p>«&nbsp;<strong>L’Editeur</strong>&nbsp;» : <span
          className='mention'
          data-mention='{{company:companyName}}'
                                                      >JH &amp; Co
        </span>, <span
                                                        className='mention' data-mention='{{company:companyType}}'
                                                               >SARL
                                                               </span> pris en sa qualité d’éditeur du Site.
        </p>
        <p>«&nbsp;<strong>Internaute</strong>&nbsp;» : toute personne, physique ou morale, de droit privé ou de droit
          public, se connectant au Site.
        </p>
        <p>«&nbsp;<strong>Produit</strong>&nbsp;»&nbsp;: bien de toute nature vendu sur le Site par l’Editeur aux
          Clients.
        </p>
        <p>«&nbsp;<strong>Site&nbsp;</strong>» : site internet accessible à l’URL <span
          className='mention'
          data-mention='{{company:websiteName}}'
                                                                                  >https://jhnco.fr
        </span>,
          ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférents.
        </p>
        <h3><strong>ARTICLE 4. CHAMP D’APPLICATION</strong></h3>
        <p>Le Site est d'accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l'acceptation par
          tout Internaute de la présente politique d’expédition. La simple connexion au Site, par quelque moyen que ce
          soit, notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière de
          la présente politique d’expédition. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le
          fait de cocher la case correspondante.
        </p>
        <p>L'Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans
          restriction.&nbsp;
        </p>
        <p>Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de
          l’Internaute. L'Internaute reconnaît la valeur de preuve des systèmes d'enregistrement automatique de
          L’Editeur et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.
        </p>
        <p>La présente politique d’expédition est applicable aux relations entre les parties à l’exclusion de toutes
          autres conditions, et notamment celles de l’Internaute.
        </p>
        <p>L'acceptation de la présente politique d’expédition suppose de la part des Internautes qu'ils jouissent de la
          capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un
          curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient
          titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.
        </p>
        <h3><strong>ARTICLE 5. LIVRAISON&nbsp;</strong></h3>
        <h4><strong>5.1. Frais de livraison</strong></h4>
        <p>Les frais de livraison ou de mise à disposition seront, en toute hypothèse, indiqués au Client avant tout
          règlement et ne concernent que les livraisons effectuées en France métropolitaine, Corse incluse. Pour tout
          autre lieu de livraison, il appartiendra au Client de prendre contact avec le service clientèle.
        </p>
        <p>Dans l’hypothèse d’une livraison du Produit au Client en boutique ou dans un lieu partenaire de l’Editeur,
          les frais afférents sont précisés au Client au moment de la commande.
        </p>
        <p>Les frais de livraison indiqués sur le Site sont entendus en euros, toutes taxes comprises.</p>
        <h4><strong>5.2. Délai de livraison</strong></h4>
        <p>Les commandes sont livrées par <span className='mention undefined' data-mention='{{shipping:allCarriers}}'>A renseigner [allCarriers]</span> dans
          un délai de <span className='mention' data-mention='{{shipping:deliveryTime}}'>4</span> jours ouvrés à compter
          du parfait encaissement du prix par L’Editeur.
        </p>
        <p>Certains produits ou certains volumes de commande peuvent néanmoins justifier un délai de livraison supérieur
          à <span className='mention' data-mention='{{shipping:deliveryTime}}'>4</span> jours ouvrés. Il en sera fait
          mention expresse à l'attention du Client lors de la validation de la commande.
        </p>
        <h4><strong>5.3. Colis détérioré</strong></h4>
        <p>En cas de livraison d'un colis manifestement et visiblement détérioré, il appartient au Client de le refuser
          afin de jouir de la garantie offerte par le transporteur. Le Client devra par ailleurs en informer le vendeur
          sans délai, afin qu'un nouveau colis lui soit préparé, puis expédié dès réception du colis détérioré en
          retour. Dans pareil cas, les délais de livraison indiqués plus haut dans les présentes conditions générales ne
          seront plus applicables.
        </p>
        <h4><strong>5.4. Réserve de propriété - transfert des risques</strong></h4>
        <p>La propriété des Produits livrés est réservée a l’Editeur jusqu'à la livraison des Produits au Client,
          nonobstant toute clause contraire, à moins que les parties n'aient ponctuellement et expressément convenu par
          écrit d'écarter la présente clause.
        </p>
        <p>Le Client supporte les risques relatifs aux Produits à compter de la commande. Pendant toute la durée de la
          réserve de propriété, le Client devra assurer à ses frais les Produits appartenant à l’Editeur contre tous
          dommages pouvant survenir et en justifier auprès de l’Editeur a première demande.
        </p>
        <h3><strong>ARTICLE 6. STIPULATIONS FINALES</strong></h3>
        <h4><strong>6.1. Droit applicable</strong></h4>
        <p>La présente politique d’expédition est soumise à l'application du droit français.</p>
        <h4><strong>6.2. Modifications de la présente politique d’expédition</strong></h4>
        <p>La présente politique d’expédition peut être modifiée à tout moment par L’Editeur. La politique d’expédition
          applicable au Client est celle en vigueur au jour de sa commande ou de sa connexion sur le présent Site, toute
          nouvelle connexion à l'espace personnel emportant acceptation le cas échéant de la nouvelle politique
          d’expédition.&nbsp;
        </p>
        <h4><strong>6.3. Litiges</strong></h4>
        <p>En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges avec un Client consommateur qui pourraient
          survenir dans le cadre de l'exécution des présentes conditions générales et dont la solution n’aura pu être
          trouvée préalablement à l’amiable entre les parties devra être soumis.
        </p>
        <p>En outre, le Client consommateur est informé de l’existence de la plateforme de règlement en ligne des
          litiges, accessibles à l’adresse URL suivante&nbsp;: <a
            href='https://ec.europa.eu/consumers/odr/main/?event=main.home2.show'
            target='_blank'
                                                               >https://ec.europa.eu/consumers/odr/main/?event=main.home2.show
          </a>
        </p>
        <p>Depuis le 1er janvier 2016, la médiation est obligatoire pour tous. Ainsi, tout professionnel vendant à des
          particuliers, est tenu de communiquer les coordonnées d'un Médiateur compétent en cas de litige, et ce peu
          importe qu'il vende à distance ou dans un magasin physique (Source: FEVAD).
        </p>
        <p><span className='mention' data-mention='{{company:mediationServiceName}}'>Contact</span> &nbsp;/&nbsp; <span
          className='mention' data-mention='{{company:mediationUrl}}'
                                                                                                                  >https://jhnco.fr/contact
        </span>
        </p>
        <h4><strong>6.4. Entièreté</strong></h4>
        <p>La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou
          du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties
          devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant
          à l'esprit et à l'objet des présentes.
        </p>
        <h4><strong>6.5. Non-renonciation</strong></h4>
        <p>L'absence d'exercice par L’Editeur des droits qui lui sont reconnus par les présentes ne pourra en aucun cas
          être interprétée comme une renonciation à faire valoir lesdits droits.
        </p>
        <h4><strong>6.6. Démarchage téléphonique</strong></h4>
        <p>Le Client est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage
          téléphonique à l’adresse <a
            href='http://www.bloctel.gouv.fr/'
            target='_blank'
                                   >http://www.bloctel.gouv.fr/
          </a>.&nbsp;
        </p>
        <h4><strong>6.7. Langues de la présente politique d’expédition</strong></h4>
        <p>La présente politique d’expédition est proposée en français.</p>
        <h4><strong>6.8. Clauses abusives</strong></h4>
        <p>Les stipulations de la présente politique d’expédition s'appliquent sous réserve du respect des dispositions
          impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un
          professionnel et un consommateur.
        </p>
        <h3><strong>ARTICLE 7. </strong>EXPéDITION &amp; RETOURS</h3>
        <h4>Article 7.1. Condition d'expédition spécifiques à la boutique</h4>
        <p><span className='mention' data-mention='{{shipping:describeTheConditions}}'>Livraison Colissimo : commandes expédiées le jour ouvré suivant, pour toute commande passée avant 12h00.</span>
        </p>
        <p>&nbsp;</p>
      </div>
    </>
  )
}

export default PolitiqueExpedition
